<template>
    <div>
      <div>
        <!-- <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Search by title" v-model="title" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" @click="searchTitle">
              Search
            </button>
          </div>
        </div> -->
  
        <div class="form-group">
          <label for="exampleFormControlSelect1"
            ><h5 style="width: 200px">
              <i class="bi bi-person-plus"></i> 양육 보고서 현황
            </h5></label
          >
          <select
            class="form-control"
            id="holiday"
            name="holiday"
            v-model="holiday"
            @change="getTotalAttend()"
          >
            <option id="" value="00">날짜를 선택하세요.</option>         
            <option
              v-for="daylist in dayLists"
              :key="daylist.dday"
              v-bind:value="daylist.dday"
              :id="daylist.dday"
              :selected="daylist.dday == daylist.cday"
            >
              {{ daylist.dday }}
            </option>
          </select>          
        </div>
      </div>
  
   <!--  재적 인원 -->
     <div style="height: 20px;"></div>
  <!--  출석 인원 -->
  <div class="card">
    <h5 class="card-header">보고서</h5>
    <div class="card-body">
      <h5 class="card-title">{{gradefullname}}</h5>
      <p class="card-text">
        <table class="table table-bordered" v-for="item in enrollList" v-bind:key="item" >
            <colgroup>
                <col width=30%>
                <col width=30%>
                <col width=40%>
            </colgroup>              
            <thead>
              <tr style="text-align: center;">
                <th scope="col">

                    <div  v-if="item.photo !== null" class="image-profilecontainer form-group  mb-2 ">
                        <img :src="photourl(item.photo)" alt="..." class="img-thumbnail" :style="profileimageStyle">
                      </div>
                      <div  v-else-if="item.photo2 !== null" class="image-profilecontainer form-group  mb-2 ">
                        <img :src="photourl(item.photo2)" alt="..." class="img-thumbnail" :style="profileimageStyle">
                      </div>                      
                      <div  v-else class="form-group mx-sm-3 mb-2" style=" text-align: left;">
                        <img src="../assets/noimage.png" style="width: 15%;">
                      </div>   

                </th>
                <th scope="col">{{item.gradename}}</th>
                <th scope="col">{{item.name}}</th>
              </tr>
            </thead>
            <tbody>
              <tr style="text-align: left;">
                <td colspan="3" v-html="getContent(item.pray)"></td>                
              </tr>
              <tr style="text-align: left;" v-show="item.note !== null">
                <td colspan="4" style="color: blue;">{{item.note}} &nbsp;&nbsp;
                  <span class="image-container" v-if="item.readyn == '1'"><img src="../assets/read1.png" alt="..."  :style="imageStyle" @click="iread(item,'0')"></span>
                  <span class="image-container" v-else><img src="../assets/read0.png"  alt="..."  :style="imageStyle" @click="iread(item,'1')"></span>
                  </td>                                
              </tr>                   
            </tbody>
          </table>   
      </p>
    </div>
  </div>

    </div>
</template>      

<script>
    import manageService from "../services/manageService";
   
    //import $ from "jquery";
    //import { mapState } from "vuex";
    
    export default {
      name: "list",
      component: {},
      data() {
        return {
            enrollList: {},  
            attendLists : {},          
            pageTotalCount: 0,
            page: 15,
            holiday: "00",
            dayLists: {},     
            currentDept: "",            
            id: this.$store.state.id,
            grade: this.$store.state.grade,
            phone: this.$store.state.phone,
            name: this.$store.state.name,
            teacherid: this.$store.state.teacherid,
            role: this.$store.state.role,
            token: this.$store.state.token,  
            classid: this.$store.state.classid,
            gradefullname : "",     
            imageWidth: 30,      
            profileimgWidth: 100,      
        };
      },
      created() {

      },
      computed: {
        //...mapState(["isLogin", "userInfo"]),
        photourl() {
          return (url) => {
            return "./upload/photo/"+url;
            };
          },  
          imageStyle() {
              return {
                width: `${this.imageWidth}px`,
                border: 'none',
              }
            },   
            profileimageStyle() {
              return {
                width: `${this.profileimgWidth}px`,
                border: 'none',
              }
            },                            
      },
      methods: {
        iread(iitem, param){
          var data = {
            classid: this.classid,
            no : iitem.no,
            readyn : param,
          }
          manageService
            .setReadCheck(data)
            .then((response) => {
              console.log("response", response);
              this.readyn = true;
              this.getTotalAttend();
            })
            .catch((e) => {
            console.log(e);
            });          
        },
        getContent(text){
            if(text){
                return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
            }
        },           
        getNum(val) {
            if (isNaN(val)) {
              return 0;
            }
            return val;
        },
        getDayList(){      //날짜 조회
            manageService
            .getDaysList()
            .then((response) => {

            this.dayLists = response.data.slice(0, this.page);        
            })
            .catch((e) => {
            console.log(e);
            });
            },

        getTotalAttend(){     //전체 출결 통계 

          if(this.holiday === "00") return;

            const data = {
                attenddate: this.holiday,
                grade: this.grade,
                classid: this.classid,
                role: this.role,
                };
  
            manageService
            .getweeklyreport(data)
            .then((response) => {

            this.enrollList = response.data;   
                  
            })
            .catch((e) => {
            console.log(e);
            });
        },
    },   
      mounted() {
        //this.getTotalAttend(); //전체 출석
        this. getDayList(); //날짜 조회
      },
    };
    </script>
    
    <style scoped>
    .list {
      text-align: left;
      max-width: 750px;
      margin: auto;
    }
    .done {
      background: oldlace;
    }
    .card-body {
      padding: 0.5rem;
    }
    .table td, .table th {
        padding: 0.2rem;
        vertical-align: middle;
    }
    @media (max-width: 600px) {
      .image-container img {
        width: 25px;
      }
      .image-profilecontainer img {
        width: 25px;
      }      
    }
    </style>
    